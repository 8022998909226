/** Dependencies */
import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

/** Components */
import ScrollToTop from "./components/common/ScrollToTop/ScrollToTop";
import Carousel from "./components/Carousel/Carousel";
import Services from "./components/Services/Services";
import Specialties from "./components/Specialties/Specialties";
import Player from "./components/Player/Player";
import Map from "./components/Map/Map";
import Comments from "./components/Comments/Comments";
import News from "./components/News/News";
import Sustainability from "./components/Sustainability/Sustainability";
import Modal from "./components/Modal/Modal";

/** Utils */
import { request } from "./utils/request";
import * as types from "./utils/methodTypes";

/** Styles */
import styles from "./App.module.scss";

import ReactGA from "react-ga4";
ReactGA.initialize(process.env.REACT_APP_GA4_ID);

function App() {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
    });
  }, []);

  const [
    { visible, leaf, imageUrl, title, subHeader, description },
    setModalSettings,
  ] = useState({
    visible: false,
    leaf: false,
    imageUrl: "",
    title: "title",
    subHeader: "",
    description: "description",
  });
  const [pages, setPages] = useState({});

  const getPages = () => {
    request("/pages", types.GET).then((response) => {
      let result = {};

      response?.map((page) => {
        result = { ...result, [page.key]: { ...page } };
      });

      setPages(result);
    });
  };

  useEffect(() => {
    const langCookie = Cookies.get("lang");
    Cookies.set("lang", langCookie ?? "en");
    getPages();
  }, []);

  return (
    <div className={styles.App}>
      {visible && (
        <Modal
          leaf={leaf}
          imageUrl={imageUrl}
          title={title}
          description={description}
          subHeader={subHeader}
          setVisibility={setModalSettings}
        />
      )}
      <div className={styles.content}>
        <Carousel />
        <Services
          {...pages?.top_services}
          setModalSettings={setModalSettings}
        />
        <Player />
        <Specialties setModalSettings={setModalSettings} />
        {/*<Comments />*/}
        <News {...pages?.news} setModalSettings={setModalSettings} />
        <Sustainability
          {...pages?.sustainability}
          setModalSettings={setModalSettings}
        />
        <Map {...pages?.contact_us} />
      </div>
      <ScrollToTop />
    </div>
  );
}

export default App;
