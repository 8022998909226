/** Dependencies */
import React, { useCallback, useEffect, useState } from "react";
import cx from "classnames";
import Cookies from "js-cookie";

/** Hooks */
import { useMediaQuery } from "../../utils/hooks";

/** Components */
import NavBar from "./NavBar/NavBar";
import SideBar from "./SideBar/SideBar";
import Logo from "../common/Logo/Logo";
import TopBar from "./TopBar/TopBar";

/** Icons */
import searchIcon from "../../assets/svg/search.svg";
import whiteSearchIcon from "../../assets/svg/search-white.svg";
import menuIcon from "../../assets/svg/menu.svg";
import whiteMenuIcon from "../../assets/svg/menu-white.svg";
import Turkiye from "../../assets/png/turkiye_icon.png";
import Spain from "../../assets/png/spain_icon.png";
import USA from "../../assets/png/usa_icon.png";

/** Styles */
import styles from "./header.module.scss";

const Header = () => {
  const [lastScroll, setLastScroll] = useState(0);
  const [reverse, setReverse] = useState(true);
  const [scrollPosition, setScrollPosition] = useState("");
  const [sideBarVisibility, setSideBarVisibility] = useState(false);
  const [topBarVisibility, setTopBarVisibility] = useState(false);

  const isMobile = useMediaQuery("(max-width: 768px)");

  const setLanguage = (lang) => {
    Cookies.set("lang", lang);
    window.location.reload();
  };

  const handleClickGoDriverPage = () => {
    window.open(`${process.env.REACT_APP_TMS_APP_URL}/driver`, "_blank");
  };
  const handleClickGoOwnerOperatorPage = () => {};

  const handleSideBarVisibility = (visibility) => {
    setSideBarVisibility(visibility);
  };

  const handleTopBarVisibility = (visibility) => {
    setTopBarVisibility(visibility);
  };

  const handleScroll = useCallback(() => {
    const currentScroll = window.scrollY;
    setReverse(currentScroll < 100);

    if (currentScroll <= 0) {
      setScrollPosition("");
      return;
    }

    if (
      currentScroll > lastScroll &&
      !scrollPosition.includes("scrollDown") &&
      currentScroll > 100
    ) {
      setScrollPosition("scrollDown");
    } else if (
      currentScroll < lastScroll &&
      scrollPosition.includes("scrollDown")
    ) {
      setScrollPosition("scrollUp");
    }
    setLastScroll(currentScroll);
  }, [lastScroll, scrollPosition]);

  useEffect(() => {
    if (window.location.pathname.includes("online-cams")) setReverse(false);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  return (
    <header
      className={cx(styles.header, styles[scrollPosition], {
        [styles.reverse]: reverse,
      })}
    >
      <TopBar
        visible={topBarVisibility}
        setTopBarVisibility={handleTopBarVisibility}
      />
      <Logo reverse={reverse} className={styles.logo} />
      {!isMobile && <NavBar reverse={reverse} />}
      <div className={styles.menuContainer}>
        <div className={styles.joinUs}>
          <div className={styles.joinUsButton}>
            <span>Join Us</span>
            <div className={styles.joinUsModal}>
              <div
                className={`${styles.joinUsModalActionButton} ${styles.joinUsModalActionButtonDriver}`}
                onClick={() => handleClickGoDriverPage()}
              >
                Driver
              </div>
              <div
                className={`${styles.joinUsModalActionButton} ${styles.joinUsModalActionButtonOwnerOperator}`}
                onClick={() => handleClickGoOwnerOperatorPage()}
              >
                Owner Operator
              </div>
            </div>
          </div>
        </div>
        <select
          className={styles.languages}
          onChange={(e) => setLanguage(e.target.value)}
        >
          <option selected={Cookies.get("lang") === "en"} value={"en"}>
            EN
          </option>
          <option selected={Cookies.get("lang") === "es"} value={"es"}>
            ES
          </option>
          <option selected={Cookies.get("lang") === "tr"} value={"tr"}>
            TR
          </option>
        </select>
        <img
          onClick={() => setTopBarVisibility(true)}
          className={styles.searchIcon}
          src={reverse ? whiteSearchIcon : searchIcon}
          alt="search"
        />
        <img
          onClick={() => setSideBarVisibility(true)}
          className={styles.menuIcon}
          src={reverse ? whiteMenuIcon : menuIcon}
          alt="menu"
        />
      </div>
      <SideBar
        visible={sideBarVisibility}
        setSideBarVisibility={handleSideBarVisibility}
      />
    </header>
  );
};

export default Header;
