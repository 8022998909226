/** Dependencies */
import React from 'react'
import {GoogleMap, useJsApiLoader} from '@react-google-maps/api'

/** Hooks */
import {useMediaQuery} from '../../utils/hooks'

const containerStyle = {
	width: '100%',
	height: '100%'
};

const mobileContainerStyle = {
	width: '100%',
	height: '100vh'
};

const center = {
	lat: 40.8230340,
	lng: -74.069162
};

const MapContainer = props => {
	const isMobile = useMediaQuery('(max-width: 768px)')
	
	const { isLoaded } = useJsApiLoader({
		id: 'google-map-script',
		googleMapsApiKey: "AIzaSyAmRiWE8HVyb47TnC09JW_3pkFpCoDr-PY"
	})
	
	const onLoad = React.useCallback(function callback(map) {
		const bounds = new window.google.maps.LatLngBounds(center);
		map.fitBounds(bounds);
	}, [])
	
	const onUnmount = React.useCallback(function callback(map) {
	}, [])
	
	return (
		isLoaded ? (
			<GoogleMap
				mapContainerStyle={isMobile ? mobileContainerStyle : containerStyle}
				center={center}
				zoom={17}
				onLoad={onLoad}
				onUnmount={onUnmount}
				options={{draggable: false, maxZoom: 17, streetViewControl: false}}
			>
				<></>
			</GoogleMap>
		) : <></>
	)
}

export default MapContainer